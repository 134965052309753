import React from "react";
// import ContentLoader from "react-content-loader";
import { RouteComponentProps } from "react-router-dom";

import "./CheckoutConfirmation.scss";
import { ThemeLink } from "../../components/ThemeLink/ThemeLink";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";
import { Container } from "../../components/Container/Container";

interface Props {
  orderId: string;
}

export const CheckoutConfirmation = (props: RouteComponentProps<Props>) => {
  const authCtx = useAuthContext();

  return (
    <div className='CheckoutConfirmation'>
      <Container>
        <div className='CheckoutConfirmation__head'>
          <h1 className='mb-2'>
            <span role='img' aria-label='tada'>
              🎉
            </span>
          </h1>
          <h1>Konfirmimi i porosisë</h1>
          <p>
            Porosia juaj është duke u procesuar, ju do të njoftoheni përmes email tuaj për detajet
            tjera!
          </p>
          <p>Për çdo gjë rreth porosive mund të na kontaktoni në numrin e telefonit: 049871214</p>
          {authCtx.isAuthenticated && (
            <ThemeLink
              to={`/my-profile/orders/${props.match.params.orderId}`}
              style={{ marginTop: 20, fontSize: 16 }}
            >
              Shiko porosinë
            </ThemeLink>
          )}
        </div>
      </Container>
    </div>
  );
};
